import router from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import type { PageTypes } from '~/utilities/constants';
import { useUserContext } from '~/utilities/context/dynamic/UserContext';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { useDataLayerContext } from '~/utilities/context/static/DataLayerContext';
import { useStaticContext } from '~/utilities/context/static/StaticContext';
import { parsePageInfoDataLayer, parseUserInfoDataLayer } from '~/utilities/vendors';

interface SpaPageLoadProps {
  pageType: PageTypes;
}

export const SpaPageLoad = ({ pageType }: SpaPageLoadProps) => {
  const { country, locale, language } = useAppContext();
  const { commonData } = useUserContext();
  const { pushToDataLayer } = useDataLayerContext();
  const [isTriggered, setIsTriggered] = useState(false);

  const {
    configuration: { slice, defaultCurrency, enableCMP, cmpDomainId },
    countryEn,
  } = useStaticContext();

  const handleSPAEvents = useCallback(() => {
    if (country && language && locale && countryEn) {
      const pageInfo = parsePageInfoDataLayer(
        locale,
        language,
        slice,
        defaultCurrency,
        countryEn,
        pageType
      );
      const userInfo = parseUserInfoDataLayer({ commonData });

      pushToDataLayer({ event: 'spa_page_load', page: pageInfo, user: userInfo }, true);
    }
  }, [
    country,
    language,
    countryEn,
    slice,
    defaultCurrency,
    pageType,
    pushToDataLayer,
    commonData,
    locale,
  ]);

  useEffect(() => {
    if (!(enableCMP && cmpDomainId)) {
      return () => {};
    }

    const eventHandler = () => {
      if (!isTriggered) {
        setIsTriggered(true);
        handleSPAEvents();
      }
    };

    window.addEventListener('OneTrustGroupsUpdated', eventHandler);

    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', eventHandler);
    };
  }, [handleSPAEvents, enableCMP, cmpDomainId, isTriggered, setIsTriggered]);

  useEffect(() => {
    if (!(enableCMP && cmpDomainId)) {
      handleSPAEvents();

      return () => {};
    }

    if (!router.isReady) {
      return () => {};
    }

    router.events.on('routeChangeStart', handleSPAEvents);

    return () => {
      router.events.off('routeChangeStart', handleSPAEvents);
    };
  }, [handleSPAEvents, enableCMP, cmpDomainId]);

  return <></>;
};
